import React, { useCallback, useState } from "react";
import {
  Col,
  Input,
  Row,
  Table as AntdTable,
  TablePaginationConfig,
} from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { FilterValue } from "antd/es/table/interface";
import * as Schemas from "../../../core/api/primio/primioSchemas";

const { Search } = Input;

interface Props<TData> {
  data?: {
    data: TData[];
    metadata: Schemas.Metadata;
  };
  columns: (
    filteredInfo: Record<string, FilterValue | null>,
    sortedInfo: SorterResult<TData>,
    currentDataSource: TData[],
  ) => ColumnsType<TData>;
  rowKey: (dataItem: TData) => string;
  pagination?: TablePaginationConfig;
  onPagination?: (page: number, pageSize: number) => void;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TData> | SorterResult<TData>[],
    extra: TableCurrentDataSource<TData>,
  ) => void;
  search?: {
    onSearch: (searchQuery: string) => void;
    placeholder?: string;
  };
  extra?: React.ReactElement;
}

const Table = <TData extends object>({
  data,
  columns,
  rowKey,
  pagination,
  onPagination,
  onChange,
  search,
  extra,
}: Props<TData>) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TData>>({});
  const [currentDataSource, setCurrentDataSource] = useState<TData[]>(
    data ? data.data : [],
  );

  const handleChange: TableProps<TData>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    if (onChange) {
      onChange(pagination, filters, sorter, extra);
    }

    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<TData>);
    setCurrentDataSource(extra.currentDataSource);
  };

  const renderColumns = useCallback(
    () => columns(filteredInfo, sortedInfo, currentDataSource),
    [columns, filteredInfo, sortedInfo, currentDataSource],
  );

  const pageSizeOptions = [10, 25, 50, 100];
  if (data && data.metadata.totalItems && data.metadata.totalItems > 100) {
    pageSizeOptions.push(data.metadata.totalItems);
  }

  return (
    <AntdTable<TData>
      dataSource={data ? data.data : []}
      size="small"
      title={() => (
        <Row>
          {search && (
            <Col>
              <Search
                placeholder={search.placeholder ?? "Search"}
                enterButton
                allowClear
                onSearch={search.onSearch}
              />
            </Col>
          )}
          <Col flex={"auto"} />
          <Col>{extra}</Col>
        </Row>
      )}
      tableLayout="auto"
      rowKey={rowKey}
      columns={renderColumns()}
      onChange={handleChange}
      pagination={{
        pageSize,
        pageSizeOptions,
        showSizeChanger: true,
        current: page,
        total: data?.metadata.totalItems,
        position: ["topRight", "bottomRight"],
        onChange: (page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
          if (onPagination) {
            onPagination(page, pageSize);
          }
        },
        ...pagination,
      }}
    />
  );
};

export default Table;
