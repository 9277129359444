import React, { useEffect, useState } from "react";
import { Cascader } from "antd";
import {
  fetchGetPlaybookDetails,
  useGetPlaybooks,
} from "../../../core/api/primio/primioComponents";
import { useToken } from "../../../core/providers/auth.provider";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import ContentTitle from "../content/content-title";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface Option {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
  contentType: "PLAYBOOK" | "CHAPTER" | "COLLECTION" | "CARD";
}

const LazyContentCascader = ({
  content,
  setContent,
}: {
  content: string[];
  setContent: (value: string[]) => void;
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const { data: playbooks = [] } = useGetPlaybooks({});
  const token = useToken();
  const { t } = useTranslation();

  useEffect(() => {
    const options = playbooks
      .filter((pb) => pb.contentState === "PUBLISHED")
      .map<Option>((pb) => ({
        value: pb.playbookUid,
        label: parseTitle(pb.title),
        isLeaf: false,
        contentType: "PLAYBOOK",
      }));
    setOptions(options);
  }, [playbooks]);

  const parseTitle = (title: string) => {
    //truncate title to 100 characters
    let cleanTitle = TextDecodeUtils(title).trim();
    if (cleanTitle.length > 40) {
      cleanTitle = cleanTitle.substring(0, 40) + "...";
    }
    return cleanTitle;
  };

  const onChange = (value: string[]) => {
    setContent(value);
  };

  const fetchPlaybookDetail = async (playbookUid: string) => {
    return await fetchGetPlaybookDetails({
      pathParams: { playbookUid },
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  };

  const loadData = async (selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    if (targetOption.contentType !== "PLAYBOOK") {
      return;
    }

    const playbookDetail = await fetchPlaybookDetail(targetOption.value);

    if (!playbookDetail.chapters) {
      return;
    }

    targetOption.children = playbookDetail.chapters.map((chapter) => ({
      label: parseTitle(chapter.title),
      value: chapter.chapterUid,
      contentType: "CHAPTER",
      children: !chapter.collections
        ? []
        : chapter.collections.map((collection) => ({
            label: parseTitle(collection.title),
            value: collection.collectionUid,
            contentType: "COLLECTION",
            children: !collection.cards
              ? []
              : collection.cards.map((card) => ({
                  label: parseTitle(card.title),
                  value: card.cardUid,
                  contentType: "CARD",
                })),
          })),
    }));

    setOptions([...options]);
  };

  const filter = (inputValue: string, path: Option[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    );

  return (
    <Cascader
      placeholder={Capitalize(
        t("form.placeholders.display_x", {
          field: t("form.items.content.label"),
        }),
      )}
      defaultValue={content}
      options={options as any[]}
      // @ts-ignore
      loadData={loadData}
      // @ts-ignore
      onChange={onChange}
      changeOnSelect
      // @ts-ignore
      displayRender={() => <LazyContentCascaderDisplay contentUids={content} />}
      multiple={false}
      // @ts-ignore
      showSearch={{ filter }}
    />
  );
};

const LazyContentCascaderDisplay = ({
  contentUids,
}: {
  contentUids: (string | undefined)[];
}) => {
  const [playbookUid, chapterUid, collectionUid, cardUid] = contentUids;

  return (
    <div>
      {playbookUid && (
        <ContentTitle
          contentType={ContentTypesEnum.PLAYBOOK}
          contentUid={playbookUid}
        />
      )}

      {chapterUid && (
        <ContentTitle
          prefix={" / "}
          contentType={ContentTypesEnum.CHAPTER}
          contentUid={chapterUid}
        />
      )}

      {collectionUid && (
        <ContentTitle
          prefix={" / "}
          contentType={ContentTypesEnum.COLLECTION}
          contentUid={collectionUid}
        />
      )}

      {cardUid && (
        <ContentTitle
          prefix={" / "}
          contentType={ContentTypesEnum.CARD}
          contentUid={cardUid}
        />
      )}
    </div>
  );
};

export default LazyContentCascader;
