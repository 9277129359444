import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Alert, Modal } from "antd";
import CreateInvitationForm, {
  CreateInvitationFormFields,
  CreateInvitationFormRef,
} from "../forms/create-invitation.form";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import {
  useCreateUserInvitation,
  useUpdateUserInvitation,
} from "../../../core/api/primio/primioComponents";
import { CreateUserInvitationDto } from "../../../core/api/primio/primioSchemas";
import { useDispatch } from "react-redux";
import InvitationAction from "../../../core/redux/stores/invitation/invitation.action";

interface Props {
  invitation?: InvitationModel;
  visible: boolean;
  onCancel: () => void;
}

const CreateInvitationModal = ({ invitation, visible, onCancel }: Props) => {
  const { t } = useTranslation();
  const createInvitationFormRef = useRef<CreateInvitationFormRef>(null);
  const dispatch = useDispatch();
  const {
    mutateAsync: createUserInvitationAsync,
    error: createUserInvitationError,
    isLoading: isCreatingUserInvitation,
    reset: resetCreateUserInvitation,
  } = useCreateUserInvitation({ onSuccess: () => handleOnCancel() });
  const {
    mutateAsync: updateUserInvitationAsync,
    error: updateUserInvitationError,
    isLoading: isUpdatingUserInvitation,
    reset: resetUpdateUserInvitation,
  } = useUpdateUserInvitation({ onSuccess: () => handleOnCancel() });

  async function handleOnOk() {
    if (!createInvitationFormRef.current) {
      return;
    }

    try {
      const formData =
        (await createInvitationFormRef.current.form.validateFields()) as CreateInvitationFormFields;

      const body: CreateUserInvitationDto = {
        name: formData.name,
        email: formData.email.trim().toLowerCase(),
        userGroups: formData.userGroups ? formData.userGroups : [],
        sendImmediately: formData.sendImmediately,
        blockSending: false,
      };

      if (invitation) {
        await updateUserInvitationAsync({
          pathParams: { invitationUid: invitation.invitationUid },
          body,
        });
        return;
      }

      await createUserInvitationAsync({ body });
    } catch (error) {
      console.error(error);
    }
  }

  const handleOnCancel = () => {
    dispatch(InvitationAction.getAllInvitations());
    onCancel();

    resetCreateUserInvitation();
    resetUpdateUserInvitation();

    if (createInvitationFormRef.current) {
      createInvitationFormRef.current.form.resetFields();
    }
  };

  const renderModalTitle = () => {
    if (invitation) {
      return Capitalize(
        t("common.edit_x", { name: t("containers.invitations.key") }),
      );
    }
    return `${Capitalize(t("common.new-plural"))} ${Capitalize(t("containers.invitations.key"))}`;
  };

  const renderOnOkLabel = () => {
    if (invitation) {
      return Capitalize(t("common.edit"));
    }
    return Capitalize(t("common.create"));
  };

  return (
    <Modal
      open={visible}
      title={renderModalTitle()}
      confirmLoading={isCreatingUserInvitation || isUpdatingUserInvitation}
      width={"80rem"}
      destroyOnClose
      onCancel={handleOnCancel}
      cancelText={Capitalize(t("common.cancel"))}
      okText={renderOnOkLabel()}
      onOk={handleOnOk}
    >
      <CreateInvitationForm
        ref={createInvitationFormRef}
        invitation={invitation}
      />
      {createUserInvitationError && "stack" in createUserInvitationError && (
        <Alert
          // @ts-ignore
          message={createUserInvitationError.stack.error.error}
          // @ts-ignore
          description={createUserInvitationError.stack.error.message}
          type="error"
        />
      )}
      {updateUserInvitationError && "stack" in updateUserInvitationError && (
        <Alert
          // @ts-ignore
          message={updateUserInvitationError.stack.error.error}
          // @ts-ignore
          description={updateUserInvitationError.stack.error.message}
          type="error"
        />
      )}
    </Modal>
  );
};

export default CreateInvitationModal;
