import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Form, Input, Switch } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import UserGroupSelectFormItem from "../form-builder/components/user-group-select.form-item";

export interface CreateInvitationFormFields {
  name: string;
  email: string;
  userGroups: string[];
  sendImmediately: boolean;
}

export interface CreateInvitationFormRef {
  form: FormInstance<CreateInvitationFormFields>;
}

interface Props {
  invitation: InvitationModel | undefined;
}

const CreateInvitationForm = forwardRef(
  ({ invitation }: Props, ref: Ref<CreateInvitationFormRef>) => {
    const [t] = useTranslation();
    const [form] = useForm<CreateInvitationFormFields>();
    const [name, setName] = useState(invitation ? invitation.name : "");
    const [email, setEmail] = useState(invitation ? invitation.email : "");
    const [userGroups, setUserGroups] = useState(
      invitation ? invitation.userGroups : [],
    );
    const [sendImmediately, setSendImmediately] = useState(false);

    useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
      form.setFields([{ name: "userGroups", value: userGroups }]);
    }, [userGroups]);

    function handleOnChange(checked: boolean) {
      form.setFields([{ name: "sendImmediately", value: checked }]);
      setSendImmediately(checked);
    }

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          initialValue={name}
          name={"name"}
          label={Capitalize(t("form.items.name.label"))}
          rules={[
            {
              required: true,
              message: t("errors.required", {
                item: t(`form.items.name.label`),
              }),
            },
          ]}
        >
          <Input
            value={name}
            type={"text"}
            style={{ width: "90%" }}
            placeholder={t("form.placeholders.type_x", {
              item: t("form.items.name.label"),
            })}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          initialValue={email}
          name={"email"}
          label={Capitalize(t("form.items.email.label"))}
          rules={[
            {
              pattern: /^[\w-.+%]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: t("translations:errors.validation.invalid-email"),
            },
            {
              required: true,
              message: t("errors.required-an", {
                item: t("form.items.email.label"),
              }),
            },
          ]}
        >
          <Input
            value={email}
            type={"text"}
            style={{ width: "90%" }}
            placeholder={Capitalize(t("form.items.email.label"))}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <UserGroupSelectFormItem
          initialValue={userGroups}
          onChange={setUserGroups}
          name={"userGroups"}
        />

        {!invitation && (
          <Form.Item
            name={"sendImmediately"}
            label={Capitalize(t("form.items.send.sent-now"))}
            tooltip={t("form.items.send.sent-now-tooltip")}
          >
            <Switch checked={sendImmediately} onChange={handleOnChange} />
          </Form.Item>
        )}
      </Form>
    );
  },
);

CreateInvitationForm.displayName = "CreateInvitationForm";

export default CreateInvitationForm;
