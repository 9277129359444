import React, { useState } from "react";
import { Button, Modal } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import Papa from "papaparse";
import {
  CreateLearningPathDto,
  MediaModel,
} from "../../../core/api/primio/primioSchemas";
import { useCreateLearningPath } from "../../../core/api/primio/primioComponents";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import LearningPathAction from "../../../core/redux/stores/learning-path/learning-path.action";
import { RcFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import parseCsvToLearningPath from "../../../core/utils/parse-csv-to-learning-path";
import { Capitalize } from "../../../core/utils/helper.utils";
import Link from "antd/lib/typography/Link";

const CreateLearningPathViaCsvModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { mutateAsync: createLearningPathAsync, isLoading } =
    useCreateLearningPath();
  const [files, setFiles] = useState<RcFile[]>([]);

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    setFiles([...files, ...fileList]);
    return false;
  };

  const handleRemove = (file: any) => {
    setFiles(files.filter((f) => f.uid !== file.uid));
  };

  const parseFile = (file: RcFile) =>
    new Promise<CreateLearningPathDto>((resolve, reject) => {
      Papa.parse<string[]>(file as any, {
        complete: (results) => resolve(parseCsvToLearningPath(results.data)),
        error: reject,
      });
    });

  const handleOk = async () => {
    if (files.length === 0) {
      return;
    }

    const learningPathDtos = await Promise.all(files.map(parseFile));

    try {
      await Promise.all(
        learningPathDtos.map(async (body) => {
          let media: MediaModel[] | undefined;

          if (body.mediaUids) {
            media = body.mediaUids.map<MediaModel>((mediaUrl) => ({
              mediaType: "IMAGE",
              uri: mediaUrl.includes("drive.google.com")
                ? convertPrivateDriveLinkToPublic(mediaUrl)
                : mediaUrl,
              displayMode: "LANDSCAPE",
              provider: "URI",
            }));
          }

          delete body.mediaUids;

          return createLearningPathAsync({
            body: {
              ...body,
              media,
            },
          });
        }),
      );
      await qc.invalidateQueries(["learning-paths"]);

      // fallback to fetch data the old way.
      dispatch(LearningPathAction.getAllLearningPaths());

      setFiles([]);
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      title={t("screens.modal.create-learning-path-from-csv.title")}
      open={open}
      destroyOnClose
      footer={
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Link href={"/assets/leerpaden-csv-voorbeelden.zip"}>
            {Capitalize(t("common.example"))}
          </Link>
          <div style={{ flex: 1, display: "flex" }} />
          <Button onClick={onClose}>{Capitalize(t("common.cancel"))}</Button>
          <Button type={"primary"} onClick={handleOk} loading={isLoading}>
            {Capitalize(t("common.create"))}
          </Button>
        </div>
      }
    >
      <Dragger
        accept={".csv"}
        multiple
        beforeUpload={handleBeforeUpload}
        onRemove={handleRemove}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">
          {t("containers.import-export.dragger.text")}
        </p>
        <p className="ant-upload-hint">
          {t("containers.import-export.dragger.hint-csv")}
        </p>
      </Dragger>
    </Modal>
  );
};

function convertPrivateDriveLinkToPublic(link: string) {
  const fileId = link.split("d/")[1].split("/view")[0];
  return `https://drive.google.com/uc?export=view&id=${fileId}`;
}

export default CreateLearningPathViaCsvModal;
