import React, { useState } from "react";
import {
  fetchGetPlaybookDetails,
  fetchGetPlaybooks,
} from "../../../../core/api/primio/primioComponents";
import { Playbook } from "../../../../core/api/primio/primioSchemas";
import { useToken } from "../../../../core/providers/auth.provider";
import ArrayToJsonFile from "./array-to-json-file";

async function getPlaybooks(token = "") {
  const playbooks = await fetchGetPlaybooks({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const details: Playbook[] = [];

  for (const playbook of playbooks) {
    const detail = await fetchGetPlaybookDetails({
      headers: {
        authorization: `Bearer ${token}`,
      },
      pathParams: { playbookUid: playbook.playbookUid },
    });
    details.push({
      ...detail,
      learningPaths: playbooks.find((p) => p.playbookUid === detail.playbookUid)
        ?.learningPaths,
    });
  }

  return details;
}

const DownloadModules = () => {
  const token = useToken();
  const [data, setData] = useState<any[]>([]);
  return (
    <div>
      <p>DownloadModules</p>
      <button
        onClick={async () => {
          const playbooks = await getPlaybooks(token);
          setData(playbooks);
        }}
      >
        FETCH
      </button>
      <button onClick={() => ArrayToJsonFile(data)}>DOWNLOAD</button>
    </div>
  );
};

export default DownloadModules;
