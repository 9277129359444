import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import exportQrFileUtils from "../../../core/utils/export-qr-file.utils";
import QrCodeModel from "../../../core/models/qr-code/qr-code.model";
import styles from "./qr-code.module.css";
import QRCode from "qrcode.react";
import { API_URL } from "../../../constants/config/env.config";

interface OwnProps {
  qrCode: QrCodeModel;
}

const QrCodeComponent = ({ qrCode }: OwnProps) => {
  const [t] = useTranslation();
  const environment = API_URL.split(".")[1];

  return (
    <div className={styles.qr_code_container}>
      <div className={styles.qr_code_wrapper}>
        <QRCode
          id={qrCode.qrCodeUid}
          value={`https://qr.primio.app/?q=${qrCode.qrCodeUid}&e=${environment}`}
          includeMargin={true}
          size={600}
          style={{
            width: "20rem",
            height: "20rem",
          }}
        />
        <Button
          type={"primary"}
          download
          icon={<DownloadOutlined />}
          onClick={() => exportQrFileUtils(qrCode)}
        >
          {Capitalize(
            t("common.download_x", {
              item: Capitalize(t("content.media-types.IMAGE.label")),
            }),
          )}
        </Button>
      </div>
    </div>
  );
};

export default QrCodeComponent;
