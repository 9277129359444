import React, { useEffect, useState } from "react";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import {
  useGetCard,
  useGetChapter,
  useGetCollection,
  useGetPlaybook,
} from "../../../core/api/primio/primioComponents";

const ContentTitle = ({
  contentUid,
  contentType,
  prefix,
}: {
  contentType: ContentTypesEnum;
  contentUid: string;
  prefix?: string;
}) => {
  const [title, setTitle] = useState("");

  const { data: playbook, isFetching: isFetchingPlaybook } = useGetPlaybook(
    { pathParams: { playbookUid: contentUid } },
    { enabled: contentType === ContentTypesEnum.PLAYBOOK },
  );
  const { data: chapter, isFetching: isFetchingChapter } = useGetChapter(
    { pathParams: { chapterUid: contentUid } },
    { enabled: contentType === ContentTypesEnum.CHAPTER },
  );
  const { data: collection, isFetching: isFetchingCollection } =
    useGetCollection(
      { pathParams: { collectionUid: contentUid } },
      { enabled: contentType === ContentTypesEnum.COLLECTION },
    );
  const { data: card, isFetching: isFetchingCard } = useGetCard(
    { pathParams: { cardUid: contentUid } },
    { enabled: contentType === ContentTypesEnum.CARD },
  );

  const isFetching =
    isFetchingPlaybook ||
    isFetchingChapter ||
    isFetchingCollection ||
    isFetchingCard;

  useEffect(() => {
    if (!playbook) {
      return;
    }
    setTitle(playbook.title);
  }, [playbook]);

  useEffect(() => {
    if (!chapter) {
      return;
    }
    setTitle(chapter.title);
  }, [chapter]);

  useEffect(() => {
    if (!collection) {
      return;
    }
    setTitle(collection.title);
  }, [collection]);

  useEffect(() => {
    if (!card) {
      return;
    }
    setTitle(card.title);
  }, [card]);

  return (
    <span title={title ? TranslateDefaults(title) : undefined}>
      {isFetching && !title && ""}

      {!isFetching && !title && prefix + "-"}

      {!isFetching && title && (
        <>
          {prefix && prefix}
          {TranslateDefaults(title).trim()}
        </>
      )}
    </span>
  );
};

export default ContentTitle;
