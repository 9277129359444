import React, { useState } from "react";
import InlineAttributeManager from "../inline-attribute-manager/inline-attribute-manager";
import {
  useCreateUserGroup,
  useDeleteUserGroup,
  useGetUserGroups,
  useUpdateApiKeyClientContentUserGroups,
  useUpdatePlaybook,
} from "../../../core/api/primio/primioComponents";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import { Dropdown, Modal, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { UserGroupRoles } from "../../../constants";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { interactiveText } from "../../styles/typography";

const InlineUserGroupAclManager = (props: {
  userGroups: string[];
  playbookUid: string;
  disabled?: boolean;
  apiKeyClientUid?: string; // used for shared content
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [userGroups, setUserGroups] = useState<string[]>(props.userGroups);
  const { data: allUserGroups = [] } = useGetUserGroups(
    {
      queryParams: { shouldExcludeUsers: true },
    },
    {
      select: (data) =>
        data.filter(
          (group) => !UserGroupRoles.includes(group.groupType.GroupName),
        ),
    },
  );

  const qc = useQueryClient();
  const { mutateAsync: updatePlaybook } = useUpdatePlaybook();
  const { mutateAsync: createUserGroup } = useCreateUserGroup();
  const { mutateAsync: deleteUserGroup } = useDeleteUserGroup();
  const { mutateAsync: updateSharedContent } =
    useUpdateApiKeyClientContentUserGroups();

  const dispatch = useDispatch();

  const handleAddUserGroupToPlaybook = async (userGroup: string) => {
    const previousUserGroups = [...userGroups];
    // optimistically add user group
    setUserGroups([...userGroups, userGroup]);

    try {
      if (props.apiKeyClientUid) {
        await updateSharedContent({
          pathParams: {
            apiKeyClientUid: props.apiKeyClientUid,
          },
          body: {
            playbookUid: props.playbookUid,
            userGroupAcl: [...userGroups, userGroup],
          },
        });
      } else {
        // add user group to playbook
        await updatePlaybook({
          pathParams: {
            playbookUid: props.playbookUid,
          },
          body: {
            userGroupAcl: [...userGroups, userGroup],
          },
        });
      }

      await qc.invalidateQueries(["v2", "playbooks"]);
      await qc.invalidateQueries(["user-groups"]);
    } catch (error) {
      // Revert optimistic update if API call fails
      setUserGroups(previousUserGroups);
    }
  };

  const handleRemoveUserGroupFromPlaybook = async (userGroup: string) => {
    const previousUserGroups = [...userGroups];
    // optimistically remove user group
    setUserGroups(userGroups.filter((g) => g !== userGroup));

    try {
      if (props.apiKeyClientUid) {
        await updateSharedContent({
          pathParams: {
            apiKeyClientUid: props.apiKeyClientUid,
          },
          body: {
            playbookUid: props.playbookUid,
            userGroupAcl: userGroups.filter((g) => g !== userGroup),
          },
        });
      } else {
        // remove user group from playbook
        await updatePlaybook({
          pathParams: {
            playbookUid: props.playbookUid,
          },
          body: {
            userGroupAcl: userGroups.filter((g) => g !== userGroup),
          },
        });
      }
      await qc.invalidateQueries(["v2", "playbooks"]);
      await qc.invalidateQueries(["user-groups"]);
    } catch (error) {
      // Revert optimistic update if API call fails
      setUserGroups(previousUserGroups);
    }
  };

  const handleCreateUserGroup = async (title: string) => {
    // replace whitespaces with lower dash
    const userGroupTitle = title.replace(/\s+/g, "_").toLowerCase().trim();

    await createUserGroup({
      body: {
        title: userGroupTitle,
      },
    });
    await handleAddUserGroupToPlaybook(userGroupTitle);
    await qc.invalidateQueries(["v2", "userGroups"]);
    await qc.invalidateQueries(["user-groups"]);
    dispatch(UserGroupAction.getAllUserGroups());
  };

  const handleDeleteUserGroup = async (groupName: string) => {
    Modal.confirm({
      title: Capitalize(
        t("translations:errors.warnings.check-delete", {
          item: t("translations:containers.user-groups.key"),
        }),
      ),
      onOk: async () => {
        await handleRemoveUserGroupFromPlaybook(groupName);
        await deleteUserGroup({
          pathParams: {
            groupName,
          },
        });
        await qc.invalidateQueries(["v2", "userGroups"]);
        await qc.invalidateQueries(["user-groups"]);
        dispatch(UserGroupAction.getAllUserGroups());
      },
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
    });
  };

  const handleOnClick = (userGroup: string) => {
    const route = RoutesEnum.USER_GROUP_DETAILS as string;
    history.push(route.replace(":title", userGroup));
  };

  return (
    <InlineAttributeManager
      data={userGroups as any[]}
      allData={allUserGroups.map((group) => group.groupType.GroupName) as any[]}
      renderItem={(userGroup: string) => (
        <Typography.Text style={interactiveText}>{userGroup}</Typography.Text>
      )}
      onAdd={handleAddUserGroupToPlaybook}
      onRemove={handleRemoveUserGroupFromPlaybook}
      onCreate={handleCreateUserGroup}
      onClick={handleOnClick}
      disabled={props.disabled}
      label={Capitalize(
        t("translations:common-x.choose-or-create", {
          item: t("translations:containers.user-groups.key"),
        }),
      )}
      renderItemExtra={(userGroup) => (
        <Dropdown
          trigger={["click"]}
          arrow
          menu={{
            items: [
              {
                key: "delete",
                label: Capitalize(t("common.delete")),
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () => handleDeleteUserGroup(userGroup),
              },
            ],
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <EllipsisOutlined />
          </div>
        </Dropdown>
      )}
    />
  );
};

export default InlineUserGroupAclManager;
