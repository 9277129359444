import React, { useState } from "react";
import { CreateCollectionSummaryResponse } from "../../../../core/models/create-content/create-collection.model";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { useDispatch, useSelector } from "react-redux";
import { selectCollectionViaUid } from "../../../../core/redux/selectors/content/content.selector";
import { disableButton } from "../../../../core/utils/media-disable-button.utils";
import CollectionSummaryModel from "../../../../core/models/content/collection-summary.model";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import SummaryTypesEnum from "../../../../core/enums/summary-types.enum";
import CollectionModel from "../../../../core/models/content/collection.model";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import MediaUploadSelectComponent from "../../media-upload/media-upload-select.component";
import MediaModel from "../../../../core/models/media/media.model";

interface Props {
  summary: CollectionSummaryModel;
}

const EditSummaryCardForm = (props: Props) => {
  const { summary } = props;
  const [media, setMedia] = useState<MediaModel | undefined>(
    summary?.media ? summary.media[0] : undefined,
  );
  const collection: CollectionModel | undefined = useSelector(
    (state: StoreInterface) =>
      selectCollectionViaUid(state, summary.collectionUid),
  );
  const dispatch = useDispatch();

  function handleOnFinish(data: CreateCollectionSummaryResponse) {
    if (!collection) {
      return;
    }

    const fieldName =
      summary.summaryType === SummaryTypesEnum.START
        ? "startSummary"
        : "endSummary";

    const mediaData: CreateCollectionSummaryResponse = {
      title: data.title,
      description: data.description,
      mediaUids: media?.mediaUid ? [media.mediaUid] : data.mediaUids,
    };

    dispatch(
      ContentAction.updateContent(ContentTypesEnum.COLLECTION, collection, {
        [fieldName]: mediaData,
      }),
    );
  }

  return (
    <CreateCardBaseForm
      cardContent={summary}
      disabledButton={disableButton(media)}
      richText={true}
      isOptional={false}
      onSubmitSummary={handleOnFinish}
    >
      <MediaUploadSelectComponent
        media={media}
        onChange={setMedia}
        contentType={ContentTypesEnum.COLLECTION}
      />
    </CreateCardBaseForm>
  );
};

export default EditSummaryCardForm;
