/* eslint-disable */
// @ts-nocheck
import React, { useEffect } from "react";
import DownloadUserProgress from "./util/download-user-progress";
import DownloadModules from "./util/download-modules";
import DownloadLearningPaths from "./util/download-learning-paths";
import Moment from "moment/moment";
import { DatePicker } from "antd";
import userData from "./data.json";
import modulesData from "./modules.json";
import learningPathsData from "./learning-paths.json";
import { LearningPath, Playbook } from "../../../core/api/primio/primioSchemas";

const { RangePicker } = DatePicker;

const progressData: any[] = userData as any[];
const playbooks: any[] = modulesData as any[];
const learningPaths: any[] = learningPathsData as any[];

enum Cohorts {
  NOT_STARTED = "NOT_STARTED",
  COMPLETED_ALL_CONTENT = "COMPLETED_ALL_CONTENT",
  COMPLETED_ALL_TRAININGS = "COMPLETED_ALL_TRAININGS",
  STARTED_TRAININGS = "STARTED_TRAININGS",
  BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_NOT_COMPLETED = "BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_NOT_COMPLETED",
  BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_COMPLETED = "BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_COMPLETED",
  NOT_STARTED_TRAININGS = "NOT_STARTED_TRAININGS",
  COMPLETED_ALL_PERFORMANCE_SUPPORT = "COMPLETED_ALL_PERFORMANCE_SUPPORT",
  STARTED_PERFORMANCE_SUPPORT = "STARTED_PERFORMANCE_SUPPORT",
  NOT_STARTED_PERFORMANCE_SUPPORT = "NOT_STARTED_PERFORMANCE_SUPPORT",
}

const getUserOfCohort = (data: any[], cohort: Cohorts) =>
  data.filter((user) => user.cohort === cohort);

function hasAccessToPlaybook(user: any, playbook: Playbook) {
  if (user.userGroups.includes("Admins")) {
    return true;
  }

  if (playbook.learningPaths?.length > 0) {
    let accessViaLearningPath = false;
    for (const learningPath of playbook.learningPaths) {
      learningPath.userGroups.some((group) => {
        if (user.userGroups.includes(group)) {
          accessViaLearningPath = true;
        }
        return false;
      });
    }
    return accessViaLearningPath;
  }

  if (playbook.userGroupAcl.length === 0) {
    return true;
  }

  // if at least one user group is also included within the playbook's user group acl
  return playbook.userGroupAcl.some((group) => user.userGroups.includes(group));
}

export function getTrainingUserProgressSummary(
  userProgress: any = {},
  learningPaths: LearningPath[],
  learningPathProgress: any,
) {
  const completedLearningPathUids: string[] = [];
  const startedLearningPathUids: string[] = [];
  const notStartedLearningPathUids: string[] = [];
  const waitingOnLearningPathUids: string[] = [];

  const playbooks = Object.entries(userProgress);

  learningPaths.forEach((learningPath) => {
    const completedContentUids: string[] = [];
    const notStartedContentUids: string[] = [];

    if (!learningPath.content) {
      return;
    }

    learningPath.content.forEach((content) => {
      const contentProgress = playbooks.find(
        (progressData) => progressData[0] === content.contentUid,
      );

      if (!contentProgress) {
        return;
      }

      const contentProgressData = contentProgress[1];

      const isCompleted =
        contentProgressData.cardsCompleted > 0 &&
        contentProgressData.totalCards === contentProgressData.cardsCompleted;
      const isNotStarted = contentProgressData.cardsCompleted === 0;

      if (isCompleted) {
        completedContentUids.push(content.contentUid);
        return;
      }

      if (isNotStarted) {
        notStartedContentUids.push(content.contentUid);
        return;
      }
    });

    const isLearningPathCompleted =
      completedContentUids.length === learningPath.content.length;
    const isLearningPathStarted =
      notStartedContentUids.length > 0 &&
      notStartedContentUids.length < learningPath.content.length;
    const isLearningPathNotStarted =
      notStartedContentUids.length === learningPath.content.length;

    if (isLearningPathNotStarted) {
      notStartedLearningPathUids.push(learningPath.learningPathUid);
      return;
    }

    if (isLearningPathCompleted) {
      completedLearningPathUids.push(learningPath.learningPathUid);
      return;
    }

    if (isLearningPathStarted) {
      // detect if user is all caught up and is waiting on learning path to continue

      const availableContentUids = [];
      if (learningPath.learningPathType) {
        switch (learningPath.learningPathType) {
          case "PROGRESS_BASED":
            // all content is available, in the sense that the user is not blocked by the system only by their own progress
            availableContentUids.push(
              ...learningPath.content.map((content) => content.contentUid),
            );
            break;
          case "TIME_BASED":
            const raw = learningPathProgress[learningPath.learningPathUid];
            if (!raw) return;

            const users = raw.users;
            const thisLearningPathProgress = Object.values(users)[0];
            const schedule = Object.entries(thisLearningPathProgress.data).sort(
              (a, b) => new Date(a[0]) - new Date(b[0]),
            );
            schedule.forEach(([contentScheduledFor, data], index) => {
              if (new Date(contentScheduledFor) < new Date() || index === 0) {
                availableContentUids.push(data.playbookUid);
              }
            });
            break;
          case "SCHEDULE_BASED":
            // look at the schedule from the learning path
            learningPath.content.forEach((content) => {
              const contentScheduledFor = new Date(
                learningPath.scheduledFor || "",
              );
              contentScheduledFor.setMinutes(
                contentScheduledFor.getMinutes() +
                  content.data?.minutesAfterStarted,
              );

              if (contentScheduledFor < new Date()) {
                availableContentUids.push(content.contentUid);
              }
            });
            break;
        }
      } else {
        // all content is available, in the sense that the user is not blocked by the system only by their own progress
        availableContentUids.push(
          ...learningPath.content.map((content) => content.contentUid),
        );
      }

      // accessibleContentUids are 'onlocked' content,
      // we need to filter those with notStarted content to know
      // if the user has indeed content they can do.
      const accessibleContentUids = availableContentUids.filter((a) =>
        notStartedContentUids.includes(a),
      );

      const isWaitingOnLearningPath = accessibleContentUids.length === 0;
      if (isWaitingOnLearningPath) {
        waitingOnLearningPathUids.push(learningPath.learningPathUid);
        return;
      }

      startedLearningPathUids.push(learningPath.learningPathUid);
      return;
    }
  });

  return {
    totalLearningPaths: learningPaths.length,
    completedLearningPathUids,
    startedLearningPathUids,
    notStartedLearningPathUids,
    waitingOnLearningPathUids,
  };
}

function getPerformanceSupportUserProgressSummary(
  userProgress: any,
  playbooks: any[],
) {
  const completedPlaybookUids: string[] = [];
  const startedPlaybookUids: string[] = [];
  const notStartedPlaybookUids: string[] = [];
  const completedCollectionUids: string[] = [];
  const startedCollectionUids: string[] = [];
  const notStartedCollectionUids: string[] = [];
  let totalCollections = 0;

  const progress = Object.entries(userProgress);

  playbooks.forEach((playbook) => {
    const playbookProgress = progress.find(
      ([uid, _]) => uid === playbook.playbookUid,
    );
    if (!playbookProgress) {
      return;
    }
    const playbookProgressData = playbookProgress[1];

    const isCompleted =
      playbookProgressData.cardsCompleted > 0 &&
      playbookProgressData.totalCards === playbookProgressData.cardsCompleted;
    const isNotStarted = playbookProgressData.cardsCompleted === 0;

    const allChapterProgress = Object.entries(playbookProgressData.chapters);

    playbook.chapters
      .filter((c) => c.contentState === "PUBLISHED")
      .forEach((chapter) => {
        const chapterProgress = allChapterProgress.find(
          ([uid, _]) => uid === chapter.chapterUid,
        );
        if (!chapterProgress) {
          return;
        }

        const chapterProgressData = chapterProgress[1];
        const allCollectionProgress = Object.entries(
          chapterProgressData.collections,
        );
        chapter.collections
          .filter((c) => c.contentState === "PUBLISHED")
          .forEach((collection) => {
            totalCollections += 1;
            const collectionProgress = allCollectionProgress.find(
              ([uid, _]) => uid === collection.collectionUid,
            );
            if (!collectionProgress) {
              return;
            }

            const collectionProgressData = collectionProgress[1];

            const isCompleted =
              collectionProgressData.cardsCompleted ===
              collectionProgressData.totalCards;
            const isNotStarted = collectionProgressData.cardsCompleted === 0;

            if (isNotStarted) {
              notStartedCollectionUids.push(collection.collectionUid);
              return;
            }

            if (isCompleted) {
              completedCollectionUids.push(collection.collectionUid);
              return;
            }

            startedCollectionUids.push(collection.collectionUid);
          });
      });

    if (isNotStarted) {
      notStartedPlaybookUids.push(playbook.playbookUid);
      return;
    }

    if (isCompleted) {
      completedPlaybookUids.push(playbook.playbookUid);
      return;
    }

    startedPlaybookUids.push(playbook.playbookUid);
  });

  return {
    playbooks: {
      totalPlaybooks: playbooks.length,
      completedPlaybookUids,
      startedPlaybookUids,
      notStartedPlaybookUids,
    },
    collections: {
      completedCollectionUids,
      startedCollectionUids,
      notStartedCollectionUids,
      totalCollections,
    },
  };
}

function ParseUserProgress(
  userProgress: any[],
  playbooks: any[],
  learningPaths: LearningPath[],
  range: Moment[],
) {
  const results: any[] = [];

  userProgress
    .filter((data) => {
      if (range.length === 0 || range.length !== 2) {
        return true;
      }

      // filter users who's lastActiveAt in within the range
      return Moment(data.user.lastActiveAt).isBetween(range[0], range[1]);
    })
    .forEach((data) => {
      const progress = data.progress.users[data.user.sub];
      const hasProgress = Object.keys(progress).length > 0;

      data.user.userGroups = data.userGroups;

      if (!hasProgress) {
        results.push({
          user: data.user,
          cohort: Cohorts.NOT_STARTED,
          report: {
            performanceSupport: {
              playbooks: {
                totalPlaybooks: 0,
                completedPlaybookUids: [],
                startedPlaybookUids: [],
                notStartedPlaybookUids: [],
              },
              collections: {
                completedCollectionUids: [],
                startedCollectionUids: [],
                notStartedCollectionUids: [],
                totalCollections: 0,
              },
            },
            learningPaths: {
              totalLearningPaths: 0,
              completedLearningPathUids: [],
              startedLearningPathUids: [],
              notStartedLearningPathUids: [],
              waitingOnLearningPathUids: [],
            },
          },
        });
        return;
      }

      data.user.firstCardSwipedOn = progress.firstActiveOn;
      data.user.lastCardSwipedOn = progress.lastActiveOn;

      const performanceSupportPlaybooks = playbooks.filter((playbook) => {
        if (playbook.contentState !== "PUBLISHED") {
          return false;
        }

        if (playbook.learningPaths.length > 0) {
          return false;
        }

        return hasAccessToPlaybook(data.user, playbook);
      });
      const performanceSupportUserProgressSummary =
        getPerformanceSupportUserProgressSummary(
          progress.progress,
          performanceSupportPlaybooks,
        );

      const availableLearningPaths = learningPaths.filter((learningPath) => {
        if (learningPath.scheduledFor) {
          const scheduledFor = new Date(learningPath.scheduledFor);
          if (scheduledFor > new Date()) {
            return false;
          }
        }

        if (!learningPath.userGroups || learningPath.userGroups.length === 0) {
          return true;
        }

        return learningPath.userGroups.some((group) =>
          data.user.userGroups.includes(group),
        );
      });

      const trainingUserProgressSummary = getTrainingUserProgressSummary(
        progress.progress,
        availableLearningPaths,
        data.learningPathProgress,
      );

      const report = {
        performanceSupport: performanceSupportUserProgressSummary,
        learningPaths: trainingUserProgressSummary,
      };

      if (
        report.learningPaths.completedLearningPathUids.length ===
          report.learningPaths.totalLearningPaths &&
        report.performanceSupport.playbooks.completedPlaybookUids.length ===
          report.performanceSupport.playbooks.totalPlaybooks
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.COMPLETED_ALL_CONTENT,
        });
        return;
      }

      if (
        report.learningPaths.completedLearningPathUids.length ===
        report.learningPaths.totalLearningPaths
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.COMPLETED_ALL_TRAININGS,
        });
        return;
      }

      if (
        report.learningPaths.waitingOnLearningPathUids.length === 0 &&
        report.learningPaths.startedLearningPathUids.length > 0
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.STARTED_TRAININGS,
        });
        return;
      }

      if (
        report.learningPaths.waitingOnLearningPathUids.length > 0 &&
        report.learningPaths.notStartedLearningPathUids.length === 0 &&
        report.performanceSupport.playbooks.completedPlaybookUids.length ===
          report.performanceSupport.playbooks.totalPlaybooks
      ) {
        results.push({
          user: data.user,
          report,
          cohort:
            Cohorts.BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_COMPLETED,
        });
        return;
      }

      if (
        report.learningPaths.waitingOnLearningPathUids.length > 0 &&
        report.learningPaths.notStartedLearningPathUids.length === 0 &&
        report.performanceSupport.playbooks.completedPlaybookUids.length <
          report.performanceSupport.playbooks.totalPlaybooks
      ) {
        results.push({
          user: data.user,
          report,
          cohort:
            Cohorts.BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_NOT_COMPLETED,
        });
        return;
      }

      if (
        report.learningPaths.notStartedLearningPathUids.length ===
          report.learningPaths.totalLearningPaths &&
        report.performanceSupport.playbooks.notStartedPlaybookUids.length ===
          report.performanceSupport.playbooks.totalPlaybooks
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.NOT_STARTED,
        });
        return;
      }

      if (
        report.learningPaths.notStartedLearningPathUids.length ===
        report.learningPaths.totalLearningPaths
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.NOT_STARTED_TRAININGS,
        });
        return;
      }

      if (
        report.performanceSupport.playbooks.completedPlaybookUids.length ===
        report.performanceSupport.playbooks.totalPlaybooks
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.COMPLETED_ALL_PERFORMANCE_SUPPORT,
        });
        return;
      }

      if (report.performanceSupport.playbooks.startedPlaybookUids.length > 0) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.STARTED_PERFORMANCE_SUPPORT,
        });
        return;
      }

      if (
        report.performanceSupport.playbooks.notStartedPlaybookUids.length ===
        report.performanceSupport.playbooks.totalPlaybooks
      ) {
        results.push({
          user: data.user,
          report,
          cohort: Cohorts.NOT_STARTED_PERFORMANCE_SUPPORT,
        });
        return;
      }
    });

  return {
    NOT_STARTED: getUserOfCohort(results, Cohorts.NOT_STARTED),
    COMPLETED_ALL_CONTENT: getUserOfCohort(
      results,
      Cohorts.COMPLETED_ALL_CONTENT,
    ),
    COMPLETED_ALL_TRAININGS: getUserOfCohort(
      results,
      Cohorts.COMPLETED_ALL_TRAININGS,
    ),
    STARTED_TRAININGS: getUserOfCohort(results, Cohorts.STARTED_TRAININGS),
    BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_NOT_COMPLETED: getUserOfCohort(
      results,
      Cohorts.BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_NOT_COMPLETED,
    ),
    BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_COMPLETED: getUserOfCohort(
      results,
      Cohorts.BLOCKED_BY_TRAININGS_BUT_PERFORMANCE_SUPPORT_COMPLETED,
    ),
    NOT_STARTED_TRAININGS: getUserOfCohort(
      results,
      Cohorts.NOT_STARTED_TRAININGS,
    ),
    COMPLETED_ALL_PERFORMANCE_SUPPORT: getUserOfCohort(
      results,
      Cohorts.COMPLETED_ALL_PERFORMANCE_SUPPORT,
    ),
    STARTED_PERFORMANCE_SUPPORT: getUserOfCohort(
      results,
      Cohorts.STARTED_PERFORMANCE_SUPPORT,
    ),
    NOT_STARTED_PERFORMANCE_SUPPORT: getUserOfCohort(
      results,
      Cohorts.NOT_STARTED_PERFORMANCE_SUPPORT,
    ),
  };
}

function GetList(
  data: any,
  cohort: Cohorts,
  learningPaths: any,
  progressData: any[],
) {
  const users = data[cohort];
  return users.map((user) => {
    const startedLearningPaths = learningPaths.filter((learningPath) =>
      user.report.learningPaths.startedLearningPathUids.includes(
        learningPath.learningPathUid,
      ),
    );
    const userProgress = progressData.find((d) => d.user.sub === user.user.sub)
      .progress.users[user.user.sub];

    if (!userProgress) return;

    startedLearningPaths.forEach((learningPath) => {
      const totalContent = learningPath.content.length;
      let completedContent = 0;
      learningPath.content.forEach((content) => {
        const contentProgress = Object.entries(userProgress.progress).find(
          (p) => p[0] === content.contentUid,
        );

        if (!contentProgress || contentProgress[1].totalCards === 0) return;

        if (
          contentProgress[1].totalCards === contentProgress[1].cardsCompleted
        ) {
          completedContent++;
        }
      });

      learningPath.progress = completedContent / totalContent;
    });

    // sort by progress
    startedLearningPaths.sort((a, b) => b.progress - a.progress);

    const nearestLearningPathToFinish = startedLearningPaths[0];

    return {
      lastActiveAt: new Date(user.user.lastActiveAt).toUTCString(),
      lastCardSwipedOn: new Date(user.user.lastCardSwipedOn).toUTCString(),
      userUid: user.user.sub,
      username: user.user.username,
      name: user.user.name ?? user.user.username,
      email: user.user.email,
      learningPathTitle: nearestLearningPathToFinish.title,
      learningPathUid: nearestLearningPathToFinish.learningPathUid,
    };
  });
}

const CohortAnalytics = () => {
  const [overview, setOverview] = React.useState<ReturnType<any>>({});
  const [startOverview, setStartOverview] = React.useState<ReturnType<any>>({});
  const [range, setRange] = React.useState<Moment[]>([]);
  const showDownloadButtons = true;

  useEffect(() => {
    const result = ParseUserProgress(
      progressData,
      playbooks,
      learningPaths,
      range,
    );

    if (range.length === 0) {
      setStartOverview(result);
    }

    setOverview(result);
  }, [range]);
  return (
    <div>
      <div>
        CohortAnalytics
        {showDownloadButtons && (
          <div>
            <DownloadUserProgress />
            <DownloadModules />
            <DownloadLearningPaths />
          </div>
        )}
        {overview && startOverview && (
          <div>
            <RangePicker
              value={range}
              onChange={(e) => {
                e === null ? setRange([]) : setRange(e);
              }}
            />
            <p>Overview</p>
            {Object.entries(overview).map(([key, value]) => {
              return (
                <p key={key}>
                  {key}: {value.length} ({startOverview[key].length})
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CohortAnalytics;
