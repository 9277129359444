import { CreateLearningPathDto } from "../api/primio/primioSchemas";

const learningPathTypeLookup: {
  [key: string]: "PROGRESS_BASED" | "SCHEDULE_BASED" | "TIME_BASED" | undefined;
} = {
  Progress: "PROGRESS_BASED",
  Scheduled: "SCHEDULE_BASED",
  Time: "TIME_BASED",
  Open: undefined,
};

function getIndexOfHeaderItem(headers: string[], header: string[]) {
  let index = -1;
  const cleanHeaderNames = headers.map((headerNameVariant) =>
    headerNameVariant.trim().toLowerCase(),
  );

  cleanHeaderNames.forEach((headerName, i) => {
    if (header.includes(headerName)) {
      index = i;
    }
  });

  return index;
}

const getLearningPathMetadata = (headers: string[], records: string[]) => {
  const titleIndex = getIndexOfHeaderItem(headers, ["title", "titel"]);
  const descriptionIndex = getIndexOfHeaderItem(headers, [
    "description",
    "omschrijving",
  ]);
  const typeIndex = getIndexOfHeaderItem(headers, ["type"]);
  const startTimeIndex = getIndexOfHeaderItem(headers, [
    "start time",
    "start tijd",
  ]);
  const colorIndex = getIndexOfHeaderItem(headers, ["kleur", "color"]);
  const mediaIndex = getIndexOfHeaderItem(headers, [
    "media",
    "afbeelding",
    "image",
  ]);

  const title = records[titleIndex];
  const description = records[descriptionIndex];
  const type = records[typeIndex] ?? "Open";
  const startTime = records[startTimeIndex] ?? "12:00";

  const metadata = {
    subtitle: "Training", // Hardcoded value because it's hardcoded in the rest of the codebase
    title,
    description,
    startTime,
    learningPathType: learningPathTypeLookup[type],
  };

  if (colorIndex !== -1) {
    metadata["color"] = records[colorIndex];
  }

  if (mediaIndex !== -1) {
    metadata["media"] = records[mediaIndex];
  }

  return metadata;
};

const parseCsvToLearningPath = (csv: string[][]): CreateLearningPathDto => {
  const learningPathMetaIndex =
    csv.findIndex((item) => item.includes("Leerpad")) + 2;

  const metadata = getLearningPathMetadata(
    csv[learningPathMetaIndex - 1],
    csv[learningPathMetaIndex],
  );

  const startOfContentIndex = csv.findIndex((item) => item.includes("Id")) + 1;
  const learningPathContent = csv.slice(startOfContentIndex);

  let scheduledFor: string | undefined;
  if (metadata.learningPathType === "SCHEDULE_BASED") {
    const firstDate = learningPathContent[0][2];
    if (!firstDate) throw new Error("No date found in first content");

    scheduledFor = new Date(`${firstDate} ${metadata.startTime}`).toISOString();
  }

  let cumulativeMinutesAfterStarted = 0;
  const content = learningPathContent
    .filter((item) => !!item[1]) // filter out lines with empty playbookUid
    .map((item, sort) => {
      const playbookUid = item[1].trim();

      if (!playbookUid) {
        throw new Error("No playbookUid found in content");
      }

      let data = {};
      if (
        metadata.learningPathType === "SCHEDULE_BASED" ||
        metadata.learningPathType === "TIME_BASED"
      ) {
        const dayOffset = item[3];
        const notificationTitle = item[5];
        const notificationMessage = item[6];

        if (dayOffset) {
          const thisMinutesAfterStarted = parseInt(dayOffset.trim()) * 24 * 60;
          cumulativeMinutesAfterStarted += thisMinutesAfterStarted;
        }

        data = {
          minutesAfterStarted: cumulativeMinutesAfterStarted,
          notification: {
            title: notificationTitle ? notificationTitle.trim() : "",
            message: notificationMessage ? notificationMessage.trim() : "",
          },
        };
      }

      return {
        contentType: "PLAYBOOK",
        contentUid: playbookUid,
        sort,
        data,
      };
    });

  const learningPathData = {
    scheduledFor,
    title: metadata.title,
    subtitle: metadata.subtitle,
    learningPathType: metadata.learningPathType,
    description: metadata.description,
    content: content as any[],
  };

  if ("color" in metadata) {
    learningPathData["color"] = metadata.color;
  }

  if ("media" in metadata) {
    // these values are not mediaUids, but urls.
    learningPathData["mediaUids"] = [metadata.media];
  }

  return learningPathData;
};

export default parseCsvToLearningPath;
