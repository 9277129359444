import React, { useState } from "react";
import { TableRowSelection } from "antd/lib/table/interface";
import { ColumnsType } from "antd/lib/table";
import {
  Button,
  Checkbox,
  DatePicker,
  Space,
  Table,
  Typography,
  Form,
  Modal,
} from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import UserModel from "../../../core/models/user/user.model";
import { GroupTags } from "../../components/group-tag";
import SelectionContextMenu from "../../components/selection-context-menu/selection-context-menu";
import {
  useDeleteUser,
  useDisableUser,
  useEnableUser,
  useGetUserGroups,
} from "../../../core/api/primio/primioComponents";
import Moment from "moment";
import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import UserAction from "../../../core/redux/stores/user/user.action";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";

const { RangePicker } = DatePicker;

interface OwnProps {
  users: UserModel[];
  onSelectGroup?: (data: UserModel[]) => void;
  onSelectNotification?: (data: UserModel[]) => void;
}

type Props = OwnProps;

enum CognitoUserStatusEnum {
  CONFIRMED = "CONFIRMED", // User has been confirmed.
  EXTERNAL_PROVIDER = "EXTERNAL_PROVIDER", // User signed in with a third-party IdP.
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD", // The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else.
  RESET_REQUIRED = "RESET_REQUIRED", // User is confirmed, but the user must request a code and reset their password before they can sign in.
  UNCONFIRMED = "UNCONFIRMED", // User has been created but not confirmed.
  UNKNOWN = "UNKNOWN", // User status isn't known.
  DISABLED = "DISABLED", // not an actual status in cognito but used to indicate that the user is disabled
  COMPROMISED = "COMPROMISED",
  ARCHIVED = "ARCHIVED",
}

enum PrimioUserStatusEnum {
  GOOD = "GOOD",
  WARNING = "WARNING",
}

const UsersOverviewScreen = ({
  users,
  onSelectGroup,
  onSelectNotification,
}: Props) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { data: userGroups = [] } = useGetUserGroups({});

  const dispatch = useDispatch();
  const isFetchingUsers = useSelector((state) =>
    selectRequesting(state, [UserAction.REQUEST_USERS]),
  );

  const { mutateAsync: disableUserAsync, isLoading: isDisablingUser } =
    useDisableUser();
  const { mutateAsync: enableUserAsync, isLoading: isEnablingUser } =
    useEnableUser();
  const { mutateAsync: deleteUserAsync, isLoading: isDeletingUser } =
    useDeleteUser();

  const columns: ColumnsType<UserModel> = [
    {
      key: "name",
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "name",
      width: 150,
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...GetColumnSearchProps({ dataIndex: "name" }),
    },
    {
      key: "email",
      title: Capitalize(t("form.items.email.label")),
      dataIndex: "email",
      width: 150,
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...GetColumnSearchProps({ dataIndex: "email" }),
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      width: 150,
      filters: userGroups.map((userGroup) => ({
        text: userGroup.groupType.GroupName,
        value: userGroup.groupType.GroupName,
      })),
      onFilter: (value, record) => record.userGroups.includes(value as string),
      render: (_, data) => <GroupTags groupNames={data.userGroups} />,
    },
    {
      key: "status",
      title: Capitalize(t("translations:common.status")),
      width: 150,
      defaultFilteredValue: [PrimioUserStatusEnum.GOOD],
      filters: Object.keys(PrimioUserStatusEnum).map((key) => ({
        text: t(`translations:primio-user-status.${key}`),
        value: key,
      })),
      onFilter: (value, record) => {
        if (value === PrimioUserStatusEnum.GOOD) {
          return (
            record.user_status === CognitoUserStatusEnum.CONFIRMED ||
            record.user_status === CognitoUserStatusEnum.EXTERNAL_PROVIDER
          );
        }

        return (
          record.user_status !== CognitoUserStatusEnum.CONFIRMED &&
          record.user_status !== CognitoUserStatusEnum.EXTERNAL_PROVIDER
        );
      },
      render: (_, data) => {
        let label = data.user_status;
        if (!data.isEnabled) {
          label = "DISABLED";
        }

        let status = PrimioUserStatusEnum.WARNING;

        if (
          label === CognitoUserStatusEnum.CONFIRMED ||
          label === CognitoUserStatusEnum.EXTERNAL_PROVIDER
        ) {
          status = PrimioUserStatusEnum.GOOD;
        }

        let color = "var(--success-color)";
        switch (status) {
          case PrimioUserStatusEnum.WARNING:
            return (
              <Typography.Text type={"warning"}>
                {t(`translations:cognito-states.${label}`)}
              </Typography.Text>
            );
          case PrimioUserStatusEnum.GOOD:
            if (label === CognitoUserStatusEnum.EXTERNAL_PROVIDER) {
              color = "var(--blue500-color)";
            }
            return <CheckOutlined style={{ color: color }} />;
        }
      },
    },
    {
      key: "last_active_at",
      title: Capitalize(t("translations:form.items.last_active_at.label")),
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        let parsedValue: Moment.Moment[] = [];

        const selectedStringKeys = selectedKeys.toString();
        const filterNotActiveUsers = selectedStringKeys === "undefined";

        if (selectedKeys.length === 2) {
          parsedValue = selectedKeys
            .toString()
            .split("|")
            .map((dateString) => Moment(dateString));
        }

        return (
          <div style={{ padding: 8 }}>
            <div style={{ marginBottom: 8 }}>
              <RangePicker
                disabled={filterNotActiveUsers}
                value={
                  parsedValue.length > 0
                    ? [parsedValue[0], parsedValue[1]]
                    : undefined
                }
                onChange={(value: any) => {
                  if (value === null) {
                    setSelectedKeys([]);
                    confirm({ closeDropdown: false });
                    return;
                  }

                  const correctValue = value as Moment.Moment[];
                  const dateStrings = correctValue
                    .map((moment) => moment.toString())
                    .join("|");
                  setSelectedKeys([dateStrings]);
                }}
              />
            </div>
            <div style={{ marginBottom: 8 }}>
              <Form.Item
                initialValue={filterNotActiveUsers}
                style={{ margin: 0 }}
                label={"User with no activity"}
              >
                <Checkbox
                  checked={filterNotActiveUsers}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedKeys(["undefined"]);
                    } else {
                      setSelectedKeys([]);
                    }
                  }}
                />
              </Form.Item>
            </div>
            <Space>
              <Button
                size={"small"}
                type={"primary"}
                onClick={() => confirm({ closeDropdown: false })}
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                {Capitalize(t("common.search"))}
              </Button>
              <Button
                size={"small"}
                style={{ width: 90 }}
                onClick={() => {
                  if (clearFilters) {
                    clearFilters();
                  }
                  setSelectedKeys([]);
                  confirm({ closeDropdown: false });
                }}
              >
                {Capitalize(t("common.reset"))}
              </Button>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        const stringValue = value.toString();
        if (stringValue === "undefined") {
          return record.last_active_at === undefined;
        }

        const parsedValue = stringValue
          .split("|")
          .map((dateString) => Moment(dateString));

        if (!record.last_active_at && parsedValue.length > 0) {
          return false;
        }

        return Moment(record.last_active_at).isBetween(
          parsedValue[0],
          parsedValue[1],
        );
      },
      sorter: (a, b) => {
        if (!a.last_active_at && !b.last_active_at) {
          return 0;
        }
        if (!a.last_active_at) {
          return -1;
        }
        if (!b.last_active_at) {
          return 1;
        }
        return a.last_active_at.getTime() - b.last_active_at.getTime();
      },
      render: (_, data) => (
        <Typography.Text title={data.last_active_at?.toLocaleString()}>
          {data.last_active_at ? data.last_active_at?.toLocaleDateString() : ""}
        </Typography.Text>
      ),
    },
    {
      key: "created_at",
      title: Capitalize(t("common.created-at")),
      dataIndex: "created_at",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        let parsedValue: Moment.Moment[] = [];
        if (selectedKeys.length === 2) {
          parsedValue = selectedKeys
            .toString()
            .split("|")
            .map((dateString) => Moment(dateString));
        }

        return (
          <div style={{ padding: 8 }}>
            <div style={{ marginBottom: 8 }}>
              <RangePicker
                value={
                  parsedValue.length > 0
                    ? [parsedValue[0], parsedValue[1]]
                    : undefined
                }
                onChange={(value: any) => {
                  if (value === null) {
                    setSelectedKeys([]);
                    confirm({ closeDropdown: false });
                    return;
                  }

                  const correctValue = value as Moment.Moment[];
                  const dateStrings = correctValue
                    .map((moment) => moment.toString())
                    .join("|");
                  setSelectedKeys([dateStrings]);
                }}
              />
            </div>
            <Space>
              <Button
                size={"small"}
                type={"primary"}
                onClick={() => confirm({ closeDropdown: false })}
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                {Capitalize(t("common.search"))}
              </Button>
              <Button
                size={"small"}
                style={{ width: 90 }}
                onClick={() => {
                  if (clearFilters) {
                    clearFilters();
                  }
                  setSelectedKeys([]);
                  confirm({ closeDropdown: false });
                }}
              >
                {Capitalize(t("common.reset"))}
              </Button>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        const stringValue = value.toString();
        const parsedValue = stringValue
          .split("|")
          .map((dateString) => Moment(dateString));

        if (!record.created_at && parsedValue.length > 0) {
          return false;
        }

        return Moment(record.created_at).isBetween(
          parsedValue[0],
          parsedValue[1],
        );
      },
      sorter: (a, b) => +new Date(a.created_at) - +new Date(b.created_at),
      render: function renderText(_, data) {
        return (
          <Typography.Text title={data.created_at.toLocaleString()}>
            {data.created_at.toLocaleDateString()}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      render: function showActions(_, data) {
        return (
          <Space size={"middle"}>
            <Link to={{ pathname: `/users/${data.username as string}` }}>
              <ContentActionComponent
                icon={<ContextActionIconComponent action={"open"} />}
              />
            </Link>
          </Space>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<UserModel> = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const pageSizeOptions = [10, 25, 50, 100];
  if (users.length > 100) {
    pageSizeOptions.push(users.length);
  }

  return (
    <>
      <SelectionContextMenu
        selectedRowKeys={selectedRowKeys}
        onClearSelection={() => setSelectedRowKeys([])}
        actions={[
          {
            key: "notification",
            label: `${Capitalize(t("common.create"))} ${t(
              "containers.notifications.key",
            )}`,
            icon: <ContainerIconComponent screen={"notifications"} />,
            onClick: (usernames) => {
              if (!onSelectNotification) return;

              onSelectNotification(
                users.filter((u) => usernames.includes(u.username)),
              );
            },
          },
          {
            key: "group",
            label: `${Capitalize(t("common.create"))} ${t(
              "containers.user-groups.key",
            )}`,
            icon: <ContainerIconComponent screen={"user-groups"} />,
            onClick: (usernames) => {
              if (!onSelectGroup) return;

              onSelectGroup(
                users.filter((u) => usernames.includes(u.username)),
              );
            },
          },
          {
            key: "enabled-disabled",
            label: Capitalize(t("translations:common.toggle-in-active")),
            icon: <UserSwitchOutlined />,
            onClick: async (usernames) => {
              const selectedUsers = users.filter((u) =>
                usernames.includes(u.username),
              );

              if (selectedUsers.every((u) => u.isEnabled)) {
                // disable all selected users
                await Promise.all(
                  selectedUsers.map((u) =>
                    disableUserAsync({
                      pathParams: { identifier: u.sub },
                    }),
                  ),
                );
                dispatch(UserAction.getAllUsers());
              } else {
                // enable all disabled users
                const disabledUsers = selectedUsers.filter((u) => !u.isEnabled);
                await Promise.all(
                  disabledUsers.map((u) =>
                    enableUserAsync({
                      pathParams: { identifier: u.sub },
                    }),
                  ),
                );
                dispatch(UserAction.getAllUsers());
              }
            },
          },
          {
            key: "delete",
            icon: <DeleteOutlined style={{ color: "var(--error-color)" }} />,
            onClick: async (usernames) => {
              Modal.confirm({
                title: Capitalize(t("translations:common.confirm")),
                icon: <ExclamationCircleOutlined />,
                content: t("translations:errors.warnings.check-delete", {
                  item: t(
                    t("translations:containers.users.key", {
                      count: usernames.length,
                    }),
                  ),
                }),
                // content: `You sure you want to delete these ${usernames.length} users?`,
                okText: Capitalize(
                  t("translations:errors.delete_x", {
                    item: t(
                      t("translations:containers.users.key", {
                        count: usernames.length,
                      }),
                    ),
                  }),
                ),
                cancelText: Capitalize(t("translations:common.cancel")),

                async onOk() {
                  const selectedUsers = users.filter((u) =>
                    usernames.includes(u.username),
                  );
                  await Promise.all(
                    selectedUsers.map((u) =>
                      deleteUserAsync({
                        pathParams: {
                          email: u.email,
                          username: u.username,
                        },
                      }),
                    ),
                  );
                  dispatch(UserAction.getAllUsers());
                },
              });
            },
          },
        ]}
      />
      <Table<UserModel>
        dataSource={users}
        columns={columns}
        rowKey={(data) => data.username}
        rowSelection={rowSelection}
        loading={
          isFetchingUsers || isDisablingUser || isEnablingUser || isDeletingUser
        }
        pagination={{
          pageSizeOptions,
          showSizeChanger: true,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout={"fixed"}
        scroll={{ x: true }}
        size={"middle"}
      />
    </>
  );
};

export default UsersOverviewScreen;
