const AppConfig = {
  contentTitleMaxLength: 40,
  contentDescriptionMaxLength: 60,
  contentTitleTrimmedLength: 25, // Used in the ContentTree for shortening the content's Title
  //
  formQuestionMaxLength: 200,
  formExplanationMaxLength: 150, // Placeholder of open question card
  formAnswerContentMaxLength: 150,
  formAnswerExplanationMaxLength: 250,
  formSourcesMaxLength: 100,
  //
  knowledgeTitleMaxLength: 50,
  knowledgeDescriptionMaxLength: 700, // Used on KNOWLEDGE_CARDS without media
  knowledgeDescriptionTrimmedLength: 350, // Used on KNOWLEDGE_CARDS with media
  //
  categoryTitleMaxLength: 35,
  //
  learningPathTitleMaxLength: 60,
  learningPathDescriptionMaxLength: 300,
  learningPathNotificationTitleMaxLength: 40,
  learningPathNotificationMessageMaxLength: 180,
  learningPathNotificationTitleDefault: "Nieuwe content beschikbaar! ✨",
  learningPathNotificationMessageDefault:
    "Ga door met je leerpad, er is wat nieuwe inhoud voor je beschikbaar! 🚀",
  //
  userGroupTitleMaxLength: 56,
  userGroupDescriptionMaxLength: 1024,
  //
  notificationTitleMaxLength: 40,
  notificationSubtitleMaxLength: 40,
  notificationMessageMaxLength: 180,
  //
  sharedContentDescriptionMaxLength: 75,
  //
  quizMaxOptions: 4,
  quizWithMediaMaxOptions: 3,
};

export default AppConfig;
