import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserGroupsPerUser } from "../../../core/redux/selectors/user-group/user-group.selector";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";

import UserAction from "../../../core/redux/stores/user/user.action";
import UserModel from "../../../core/models/user/user.model";
import EmptyStateScreen from "../../screens/empty-state.screen";
import UsersOverviewScreen from "../../screens/users/users-overview.screen";
import CreateUserGroupModal from "../../components/modals/create-user-group.modal";
import CreateNotificationModal from "../../components/modals/create-notification.modal";

const UsersContainer = () => {
  const [isModalUserGroupVisible, setIsModalUserGroupVisible] =
    useState<boolean>(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>();
  const dispatch = useDispatch();
  const usersWithUserGroups: UserModel[] = useSelector(getAllUserGroupsPerUser);

  useEffect(() => {
    dispatch(UserAction.getAllUsers());
  }, []);

  return (
    <PageLayoutComponent showSider={false} menuItems={[]} title={"users"}>
      {!usersWithUserGroups || usersWithUserGroups.length === 0 ? (
        <EmptyStateScreen route={"users"} />
      ) : (
        <UsersOverviewScreen
          users={usersWithUserGroups}
          onSelectGroup={onShowUserGroupModal}
          onSelectNotification={onShowNotificationModal}
        />
      )}

      <CreateUserGroupModal
        group={undefined}
        selectedUsers={selectedUsers}
        visible={isModalUserGroupVisible}
        onCancel={onHideUserGroupModal}
      />

      <CreateNotificationModal
        notification={undefined}
        notifiable={selectedUsers}
        visible={isModalNotificationVisible}
        onCancel={onHideNotificationModal}
      />
    </PageLayoutComponent>
  );

  function onShowUserGroupModal(data: UserModel[]) {
    setSelectedUsers(data);
    setIsModalUserGroupVisible(true);
  }

  function onHideUserGroupModal() {
    setIsModalUserGroupVisible(false);
  }

  function onShowNotificationModal(data: UserModel[]) {
    setSelectedUsers(data);
    setIsModalNotificationVisible(true);
  }

  function onHideNotificationModal() {
    setIsModalNotificationVisible(false);
  }
};

export default UsersContainer;
