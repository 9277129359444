import React, { useRef } from "react";
import CreateLearningPathForm, {
  CreateLearningFormRef,
} from "../forms/create-learning-path.form";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import {
  useCreateLearningPath,
  useUpdateLearningPath,
} from "../../../core/api/primio/primioComponents";
import { LearningPathTypeEnum } from "../../../core/enums/learning-path-state.enum";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import LearningPathAction from "../../../core/redux/stores/learning-path/learning-path.action";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";

interface OwnProps {
  learningPath?: LearningPathModel;
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps;

const CreateLearningPathModal = ({
  visible,
  onCancel,
  learningPath,
}: Props) => {
  const [t] = useTranslation();
  const qc = useQueryClient();
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const createLearningFormRef = useRef<CreateLearningFormRef>(null);
  const { mutateAsync: createLearningPathAsync, isLoading: isCreating } =
    useCreateLearningPath({
      onSuccess: async () => {
        await qc.invalidateQueries(["learning-paths"]);

        // fallback to fetch data the old way.
        dispatch(LearningPathAction.getAllLearningPaths());
      },
    });
  const { mutateAsync: editLearningPathAsync, isLoading: isEditing } =
    useUpdateLearningPath({
      onSuccess: async () => {
        await qc.invalidateQueries(["learning-paths"]);

        // fallback to fetch data the old way.
        dispatch(LearningPathAction.getAllLearningPaths());
      },
    });

  return (
    <Modal
      open={visible}
      title={renderTitleLabel()}
      onCancel={handleOnCancel}
      destroyOnClose
      width={"80rem"}
      footer={[
        <Button key={0} type={"text"} onClick={handleOnCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <Button
          key={1}
          onClick={() => {
            handleOnCancel();
            openModal(ModalNames.CREATE_LEARNING_PATH_VIA_CSV);
          }}
        >
          {t("translations:common.create-via-csv")}
        </Button>,
        <Button
          key={2}
          type={"primary"}
          onClick={handleOnOk}
          loading={isCreating || isEditing}
        >
          {learningPath
            ? Capitalize(t("common.edit"))
            : Capitalize(t("common.create"))}
        </Button>,
      ]}
    >
      <CreateLearningPathForm
        ref={createLearningFormRef}
        learningPath={learningPath}
      />
    </Modal>
  );

  async function handleOnOk() {
    try {
      if (!createLearningFormRef.current) return;

      const res = await createLearningFormRef.current.form.validateFields();

      if (!learningPath) {
        await createLearningPathAsync({
          body: {
            color: res.color,
            content: [],
            description: res.description,
            learningPathType:
              res.learningPathType === "OPEN"
                ? undefined
                : res.learningPathType,
            mediaUids: res.mediaUid ? [res.mediaUid] : undefined,
            scheduledFor:
              res.learningPathType === LearningPathTypeEnum.SCHEDULE_BASED
                ? "1900-01-01T00:00:00+0000"
                : undefined,
            subtitle: "Training", // Hardcoded value until we decide on dynamic solution
            title: res.title,
            userGroups: res.userGroups ?? [],
          },
        });
      } else {
        await editLearningPathAsync({
          pathParams: {
            learningPathUid: learningPath.learningPathUid,
          },
          body: {
            color: res.color,
            content: res.content,
            description: res.description ?? learningPath.description,
            mediaUids: res.mediaUid ? [res.mediaUid] : undefined,
            subtitle: "Training", // Hardcoded value until we decide on dynamic solution
            title: res.title,
            userGroups: res.userGroups ?? learningPath.userGroups,
          },
        });
      }

      handleOnCancel();
    } catch {
      // catch'd but only to not present a fullscreen error on dev
    }
  }

  function renderTitleLabel() {
    if (learningPath) {
      return Capitalize(t("common.edit_x", { name: learningPath.title }));
    }
    return `${Capitalize(t("common.new"))} ${Capitalize(
      t("containers.learning-path.key"),
    )}`;
  }

  function handleOnCancel() {
    onCancel();
    if (createLearningFormRef.current) {
      createLearningFormRef.current.form.resetFields();
    }
  }
};

export default CreateLearningPathModal;
