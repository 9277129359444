import { ColumnType } from "antd/lib/table";
import { Typography } from "antd";
import React from "react";

interface Props {
  title: string;
  dataIndex: string;
}

function sortDate<Type>(dataIndex: string) {
  return (a: Type, b: Type) => {
    if (!a[dataIndex] && b[dataIndex]) {
      return -1;
    }
    if (a[dataIndex] && !b[dataIndex]) {
      return 1;
    }
    if (!a[dataIndex] || !b[dataIndex]) {
      return 0;
    }

    // @ts-ignore niet drammen!
    return new Date(a[dataIndex]).getTime() - new Date(b[dataIndex]).getTime();
  };
}

export default function createDateColumn<Type>({
  dataIndex,
  title,
}: Props): ColumnType<Type> {
  return {
    title,
    dataIndex,
    sorter: sortDate<Type>(dataIndex),
    render: (value: string) => (
      <Typography.Text
        title={value ? new Date(value).toLocaleString() : undefined}
      >
        {value ? new Date(value).toLocaleDateString() : ""}
      </Typography.Text>
    ),
  };
}
